// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline-popup-content {
    display: block;
    background-color: var(--main-bg-color);
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    border-radius: 4px;
    left: 2px;
    right: 2px;
    bottom: 52px;
    color: var(--main-fg-color);
    border-top: 1px solid var(--main-border-color);
    z-index: 100;
}
`, "",{"version":3,"sources":["webpack://./src/css/TimeLinePopUp.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,sCAAsC;IACtC,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,YAAY;IACZ,2BAA2B;IAC3B,8CAA8C;IAC9C,YAAY;AAChB","sourcesContent":[".timeline-popup-content {\n    display: block;\n    background-color: var(--main-bg-color);\n    padding-top: 10px;\n    padding-bottom: 10px;\n    position: absolute;\n    border-radius: 4px;\n    left: 2px;\n    right: 2px;\n    bottom: 52px;\n    color: var(--main-fg-color);\n    border-top: 1px solid var(--main-border-color);\n    z-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
