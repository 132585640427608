import React from "react";
import { MantineProvider } from "@mantine/core";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "App";
import {
  PACKAGE_VERSION,
  PAELLA_CORE_VERSION,
  PAELLA_BASIC_PLUGINS_VERSION,
} from "version";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

console.log(`Weblecture player version: ${PACKAGE_VERSION}`);
console.log(`Paella core version: ${PAELLA_CORE_VERSION}`);
console.log(`Paella plugins version: ${PAELLA_BASIC_PLUGINS_VERSION}`);
root.render(
  <MantineProvider withGlobalStyles withNormalizeCSS>
    <App />
  </MantineProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
