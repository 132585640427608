import { useState, useEffect, useCallback } from "react";
import axios, { AxiosError } from "axios";
import { IManifest } from "types/manifest";
import { getManifestUrlFunction } from "utils/paella-manifests";
// import { getUrlParameter } from "utils/urls";

export default function useDataJson() {
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);
  const [dataJson, setDataJson] = useState<IManifest | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<AxiosError | undefined>(undefined);

  const getDataJson = useCallback(async () => {
    // const videoId = getUrlParameter("id");
    const manifestUrl = getManifestUrlFunction(window.location.href, "");
    try {
      const { data, status } = await axios.get<IManifest>(
        `${manifestUrl}/data.v2.json`,
        { withCredentials: true },
      );
      const responseJson = data;

      if (status === 200 && responseJson) {
        setDataJson(responseJson);
        console.log(`Fetching data.v2.json... OK`);
      } else {
        console.log(`Fetching dat.v2.json... ERROR`);
        setDataJson(undefined);
      }
    } catch (requestError) {
      const axiosError = requestError as AxiosError;
      setError(axiosError);
      setDataJson(undefined);
    } finally {
      setLoading(false);
    }
  }, [setDataJson]);

  useEffect(() => {
    getDataJson();
  }, [getDataJson]);

  useEffect(() => {
    if (dataJson) {
      setPreviewUrl(dataJson.metadata.preview);
    }
  }, [dataJson]);

  return { dataJson, previewUrl, loading, error };
}
