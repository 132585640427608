// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.player-container {
    position: relative;
    overflow: hidden;
}

svg {
    pointer-events: none;
}

button:focus {
    /*border: 3px solid var(--highlight-bg-color-hover) !important;
    box-shadow: 0px 0px 7px 0px var(--highlight-bg-color-hover) !important;
    */
}

.player-container button,
.video-container .video-canvas .button-area button,
.button-group .button-plugin-container button {
    outline-style: none;
}

.player-container button:focus-visible,
.video-container .video-canvas .button-area button:focus-visible,
.button-group .button-plugin-container button:focus-visible {
    outline-style: solid;
    outline-color: var(--main-outline-color);
    outline-width: 2px;
}`, "",{"version":3,"sources":["webpack://./src/css/base.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI;;KAEC;AACL;;AAEA;;;IAGI,mBAAmB;AACvB;;AAEA;;;IAGI,oBAAoB;IACpB,wCAAwC;IACxC,kBAAkB;AACtB","sourcesContent":[".player-container {\n    position: relative;\n    overflow: hidden;\n}\n\nsvg {\n    pointer-events: none;\n}\n\nbutton:focus {\n    /*border: 3px solid var(--highlight-bg-color-hover) !important;\n    box-shadow: 0px 0px 7px 0px var(--highlight-bg-color-hover) !important;\n    */\n}\n\n.player-container button,\n.video-container .video-canvas .button-area button,\n.button-group .button-plugin-container button {\n    outline-style: none;\n}\n\n.player-container button:focus-visible,\n.video-container .video-canvas .button-area button:focus-visible,\n.button-group .button-plugin-container button:focus-visible {\n    outline-style: solid;\n    outline-color: var(--main-outline-color);\n    outline-width: 2px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
