(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("paella-core"));
	else if(typeof define === 'function' && define.amd)
		define(["paella-core"], factory);
	else if(typeof exports === 'object')
		exports["paella-basic-plugins"] = factory(require("paella-core"));
	else
		root["paella-basic-plugins"] = factory(root[undefined]);
})(self, (__WEBPACK_EXTERNAL_MODULE__179__) => {
return 