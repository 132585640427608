// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Button plugins */
:root {
	--button-fixed-width: 40px;
	--button-fixed-height: 40px;
    --button-fixed-width-sm: 40px;
    --button-fixed-height-sm: 40px;
    --progress-indicator-height: 10px;
    --playback-bar-height: calc(var(--button-fixed-height) + var(--progress-indicator-height));
    --playback-bar-height-sm: calc(var(--button-fixed-height-sm) + var(--progress-indicator-height));
    --button-icon-size: calc(var(--button-fixed-width) / 2);
    --button-icon-size-sm: calc(var(--button-fixed-width-sm) / 2);
	--progress-indicator-handler-size: 20px;
    --playback-bar-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/css/sizes.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;CACC,0BAA0B;CAC1B,2BAA2B;IACxB,6BAA6B;IAC7B,8BAA8B;IAC9B,iCAAiC;IACjC,0FAA0F;IAC1F,gGAAgG;IAChG,uDAAuD;IACvD,6DAA6D;CAChE,uCAAuC;IACpC,0BAA0B;AAC9B","sourcesContent":["/* Button plugins */\n:root {\n\t--button-fixed-width: 40px;\n\t--button-fixed-height: 40px;\n    --button-fixed-width-sm: 40px;\n    --button-fixed-height-sm: 40px;\n    --progress-indicator-height: 10px;\n    --playback-bar-height: calc(var(--button-fixed-height) + var(--progress-indicator-height));\n    --playback-bar-height-sm: calc(var(--button-fixed-height-sm) + var(--progress-indicator-height));\n    --button-icon-size: calc(var(--button-fixed-width) / 2);\n    --button-icon-size-sm: calc(var(--button-fixed-width-sm) / 2);\n\t--progress-indicator-handler-size: 20px;\n    --playback-bar-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
