// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-layout-button {
	border: none;
	border-radius: 10%;
	box-shadow: 1px 1px 5px 0px var(rgba(0,0,0,0.8));
	background-size: 100% 100%;
	display: block;
	position: absolute;
	background-color: var(--main-bg-color-hover);
	border: none;
	border-radius: 5px;
	padding: 0.5%;
}

.video-layout-button:hover {
	background-color: var(--highlight-bg-color);
}

.video-layout-button:active {
	background-color: var(--highlight-bg-color-hover);
}

.video-player {
	box-shadow: 2px 2px 4px 0px rgba(90,90,90,0.8);
}

`, "",{"version":3,"sources":["webpack://./src/css/VideoLayout.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,kBAAkB;CAClB,gDAAgD;CAChD,0BAA0B;CAC1B,cAAc;CACd,kBAAkB;CAClB,4CAA4C;CAC5C,YAAY;CACZ,kBAAkB;CAClB,aAAa;AACd;;AAEA;CACC,2CAA2C;AAC5C;;AAEA;CACC,iDAAiD;AAClD;;AAEA;CACC,8CAA8C;AAC/C","sourcesContent":[".video-layout-button {\n\tborder: none;\n\tborder-radius: 10%;\n\tbox-shadow: 1px 1px 5px 0px var(rgba(0,0,0,0.8));\n\tbackground-size: 100% 100%;\n\tdisplay: block;\n\tposition: absolute;\n\tbackground-color: var(--main-bg-color-hover);\n\tborder: none;\n\tborder-radius: 5px;\n\tpadding: 0.5%;\n}\n\n.video-layout-button:hover {\n\tbackground-color: var(--highlight-bg-color);\n}\n\n.video-layout-button:active {\n\tbackground-color: var(--highlight-bg-color-hover);\n}\n\n.video-player {\n\tbox-shadow: 2px 2px 4px 0px rgba(90,90,90,0.8);\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
