// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (forced-colors: active) {
    /* selected menu item */
    ul.menu-button-content li.menu-button-item button.selected {
		border: 2px solid white;
	}

    /* Progress indicator */
    .progress-indicator-content {
        border: 5px solid white;
        box-sizing: border-box;
    }

    .progress-indicator-remaining {
        border: 3px solid gray;
        box-sizing: border-box;
    }

    .progress-indicator-handler {
        border: 5px solid white;
        box-sizing: border-box;
    }
    

}
`, "",{"version":3,"sources":["webpack://./src/css/ForcedColors.css"],"names":[],"mappings":";AACA;IACI,uBAAuB;IACvB;EACF,uBAAuB;CACxB;;IAEG,uBAAuB;IACvB;QACI,uBAAuB;QACvB,sBAAsB;IAC1B;;IAEA;QACI,sBAAsB;QACtB,sBAAsB;IAC1B;;IAEA;QACI,uBAAuB;QACvB,sBAAsB;IAC1B;;;AAGJ","sourcesContent":["\n@media (forced-colors: active) {\n    /* selected menu item */\n    ul.menu-button-content li.menu-button-item button.selected {\n\t\tborder: 2px solid white;\n\t}\n\n    /* Progress indicator */\n    .progress-indicator-content {\n        border: 5px solid white;\n        box-sizing: border-box;\n    }\n\n    .progress-indicator-remaining {\n        border: 3px solid gray;\n        box-sizing: border-box;\n    }\n\n    .progress-indicator-handler {\n        border: 5px solid white;\n        box-sizing: border-box;\n    }\n    \n\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
