// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
ul.menu-button-content {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.menu-button-title {
	list-style: none;
    font-family: sans-serif;
    text-align: center;
    padding: 5px;
    border-bottom: 1px solid #938e8e;
}

ul.menu-button-content li.menu-button-item {
	min-width:40px;
}

ul.menu-button-content li.menu-button-item button {
	background: none;
	border: none;
	width: 100%;
	color: var(--main-fg-color);
	display: flex;
	justify-content: space-around;
}

ul.menu-button-content li.menu-button-item button:hover {
	background-color: var(--secondary-bg-color);
}

ul.menu-button-content li.menu-button-item button.selected {
	background-color: var(--highlight-bg-color);
}

ul.menu-button-content li.menu-button-item button.selected:hover {
	background-color: var(--highlight-bg-color-hover);
}

ul.menu-button-content li.menu-button-item button i.menu-icon {
	pointer-events: none;
}

ul.menu-button-content li.menu-button-item button i.menu-icon svg {
	width: 100%;
    height: 100%;
    fill: var(--main-fg-color);
	color: var(--main-fg-color);
	height: 40px;
}

ul.menu-button-content li.menu-button-item button span.menu-title {
	height: 40px;
	line-height: 40px;
	padding-left: 4px;
	padding-right: 4px;
	pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/css/MenuButton.css"],"names":[],"mappings":";AACA;CACC,qBAAqB;CACrB,SAAS;CACT,UAAU;AACX;;AAEA;CACC,gBAAgB;IACb,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,gCAAgC;AACpC;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,gBAAgB;CAChB,YAAY;CACZ,WAAW;CACX,2BAA2B;CAC3B,aAAa;CACb,6BAA6B;AAC9B;;AAEA;CACC,2CAA2C;AAC5C;;AAEA;CACC,2CAA2C;AAC5C;;AAEA;CACC,iDAAiD;AAClD;;AAEA;CACC,oBAAoB;AACrB;;AAEA;CACC,WAAW;IACR,YAAY;IACZ,0BAA0B;CAC7B,2BAA2B;CAC3B,YAAY;AACb;;AAEA;CACC,YAAY;CACZ,iBAAiB;CACjB,iBAAiB;CACjB,kBAAkB;CAClB,oBAAoB;AACrB","sourcesContent":["\nul.menu-button-content {\n\tlist-style-type: none;\n\tmargin: 0;\n\tpadding: 0;\n}\n\n.menu-button-title {\n\tlist-style: none;\n    font-family: sans-serif;\n    text-align: center;\n    padding: 5px;\n    border-bottom: 1px solid #938e8e;\n}\n\nul.menu-button-content li.menu-button-item {\n\tmin-width:40px;\n}\n\nul.menu-button-content li.menu-button-item button {\n\tbackground: none;\n\tborder: none;\n\twidth: 100%;\n\tcolor: var(--main-fg-color);\n\tdisplay: flex;\n\tjustify-content: space-around;\n}\n\nul.menu-button-content li.menu-button-item button:hover {\n\tbackground-color: var(--secondary-bg-color);\n}\n\nul.menu-button-content li.menu-button-item button.selected {\n\tbackground-color: var(--highlight-bg-color);\n}\n\nul.menu-button-content li.menu-button-item button.selected:hover {\n\tbackground-color: var(--highlight-bg-color-hover);\n}\n\nul.menu-button-content li.menu-button-item button i.menu-icon {\n\tpointer-events: none;\n}\n\nul.menu-button-content li.menu-button-item button i.menu-icon svg {\n\twidth: 100%;\n    height: 100%;\n    fill: var(--main-fg-color);\n\tcolor: var(--main-fg-color);\n\theight: 40px;\n}\n\nul.menu-button-content li.menu-button-item button span.menu-title {\n\theight: 40px;\n\tline-height: 40px;\n\tpadding-left: 4px;\n\tpadding-right: 4px;\n\tpointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
