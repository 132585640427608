import React from "react";
import { Center, LoadingOverlay, Stack } from "@mantine/core";
import "./App.css";
import AppContent from "components/AppContent/AppContent";
import useDataJson from "hooks/use-data-json/use-data-json";

function App() {
  const { dataJson, error } = useDataJson();

  if (error) {
    return (
      <Center style={{ width: "100%", height: "100vh" }}>
        <Stack align="center">
          {error.response && error.response.status === 401 ? (
            <div>This lecture is restricted (401).</div>
          ) : (
            <div>
              There was an error loading the lecture (
              {error.response ? error.response.status : "Unknown"}).
            </div>
          )}
        </Stack>
      </Center>
    );
  }

  if (dataJson) {
    return (
      <div className="container">
        <AppContent />
      </div>
    );
  }
  return <LoadingOverlay visible />;
}

export default App;
