import { utils } from "paella-core";

const { getUrlParameter } = utils;

export function getManifestUrlFunction(repoUrl, videoId) {
  // const serverBefore2021 = "opencast-media-test"; // <= 2020
  // const serverAfter2021 = "opencast-media-test"; // > 2020
  // const serverForQa = "montblanc"; // QA

  // Get the URL and extract the last 2 parts of the path. The first will be
  // the year and the second the videoId
  console.log(repoUrl, videoId);
  const url = new URL(window.location.href);
  // If url ends with /, remove it
  if (url.pathname.endsWith("/")) {
    url.pathname = url.pathname.slice(0, -1);
  }
  const path = url.pathname.split("/");
  const year = path[path.length - 2];
  const videoIdFromPath = path[path.length - 1];

  // origin, test
  // const year = getUrlParameter("year");
  const isLocal = getUrlParameter("local");

  if (isLocal === "true") {
    return `/repository/${videoId}`;
  }

  const manifestUrl = `/${year}/${videoIdFromPath}`;
  console.log("Generating manifest url ... OK");
  console.log(`Manifest url: ${manifestUrl} (local: ${isLocal} year: ${year})`);
  return manifestUrl;
}

export function disableCorsCredentialsForPlugin(pluginName, jsonConfig) {
  const newConfig = jsonConfig;
  newConfig.plugins[pluginName].corsConfig.withCredentials = false;
  newConfig.plugins[pluginName].corsConfig.requestHeaders[
    "Access-Control-Allow-Credentials"
  ] = false;
  return newConfig;
}
