// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.captions-canvas {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 50;
    pointer-events: none;
}

.captions-canvas .text-container {
    width: 80%;
    background-color: var(--main-bg-color);
    border-radius: 5px;
    color: var(--main-fg-color);
    position: absolute;
    bottom: 5px;
    left: 10%;
    right: 10%;
    box-sizing: border-box;
    padding: 10px;
    font-size: 15px;
    text-align: center;
}

.captions-canvas.visible-ui .text-container {
    bottom: 55px;
}

.captions-canvas.size-s .text-container {
    font-size: 18px;
}

.captions-canvas.size-m .text-container {
    font-size: 20px;
}

.captions-canvas.size-l .text-container {
    font-size: 24px;
}

.captions-canvas.size-xl .text-container {
    font-size: 30px;
}

.captions-canvas.size-xxl .text-container {
    font-size: 34px;
}
`, "",{"version":3,"sources":["webpack://./src/css/CaptionCanvas.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,WAAW;IACX,YAAY;IACZ,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI,UAAU;IACV,sCAAsC;IACtC,kBAAkB;IAClB,2BAA2B;IAC3B,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,UAAU;IACV,sBAAsB;IACtB,aAAa;IACb,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".captions-canvas {\n    position: absolute;\n    top: 0px;\n    left: 0px;\n    bottom: 0px;\n    width: 100%;\n    height: 100%;\n    z-index: 50;\n    pointer-events: none;\n}\n\n.captions-canvas .text-container {\n    width: 80%;\n    background-color: var(--main-bg-color);\n    border-radius: 5px;\n    color: var(--main-fg-color);\n    position: absolute;\n    bottom: 5px;\n    left: 10%;\n    right: 10%;\n    box-sizing: border-box;\n    padding: 10px;\n    font-size: 15px;\n    text-align: center;\n}\n\n.captions-canvas.visible-ui .text-container {\n    bottom: 55px;\n}\n\n.captions-canvas.size-s .text-container {\n    font-size: 18px;\n}\n\n.captions-canvas.size-m .text-container {\n    font-size: 20px;\n}\n\n.captions-canvas.size-l .text-container {\n    font-size: 24px;\n}\n\n.captions-canvas.size-xl .text-container {\n    font-size: 30px;\n}\n\n.captions-canvas.size-xxl .text-container {\n    font-size: 34px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
