// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
div.error-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    bottom: 0px;
}

div.error-container div {
    text-align: center;
}

div.error-container i {
    width: 350px;
    height: 350px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 500px) {
    div.error-container i {
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 800px) {
    div.error-container i {
        width: 200px;
        height: 200px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/css/Error.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,oBAAoB;IACpB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,QAAQ;IACR,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI;QACI,YAAY;QACZ,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,aAAa;IACjB;AACJ","sourcesContent":["\ndiv.error-container {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    z-index: 1000;\n    pointer-events: none;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    top: 0px;\n    bottom: 0px;\n}\n\ndiv.error-container div {\n    text-align: center;\n}\n\ndiv.error-container i {\n    width: 350px;\n    height: 350px;\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n@media (max-width: 500px) {\n    div.error-container i {\n        width: 100px;\n        height: 100px;\n    }\n}\n\n@media (max-width: 800px) {\n    div.error-container i {\n        width: 200px;\n        height: 200px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
