// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
div.loader-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.6);
}

div.loader-container i {
    width: 350px;
    height: 350px;
    display: block;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 500px) {
    div.loader-container i {
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 800px) {
    div.loader-container i {
        width: 200px;
        height: 200px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/css/Loader.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,oBAAoB;IACpB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,0CAA0C;AAC9C;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;IACd,kCAAkC;AACtC;;AAEA;IACI;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,aAAa;IACjB;AACJ","sourcesContent":["\ndiv.loader-container {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    z-index: 1000;\n    pointer-events: none;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: rgba(255, 255, 255, 0.6);\n}\n\ndiv.loader-container i {\n    width: 350px;\n    height: 350px;\n    display: block;\n    animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n@media (max-width: 500px) {\n    div.loader-container i {\n        width: 100px;\n        height: 100px;\n    }\n}\n\n@media (max-width: 800px) {\n    div.loader-container i {\n        width: 200px;\n        height: 200px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
