import React, { useEffect, useRef } from "react";
import getCernCustomPluginsContext from "@cern-vc/cern-paella-plugins";
import getBasicPluginContext from "paella-basic-plugins";
import { Paella, utils } from "paella-core";
import getSlidePluginContext from "paella-slide-plugins";
import {
  disableCorsCredentialsForPlugin,
  getManifestUrlFunction,
} from "utils/paella-manifests";
import { getUrlParameter } from "utils/urls";

function Player() {
  const paella = useRef();

  const customLoadConfig = async (configUrl) => {
    console.log("Using custom configuration loading function.");
    console.log("Loading configuration from: ", configUrl);
    const response = await fetch("/paella-config/config.json");
    let result = await response.json();

    const isLocal = getUrlParameter("local");
    if (isLocal === "true") {
      result = disableCorsCredentialsForPlugin(
        "es.upv.paella.hlsVideoFormat",
        result,
      );
      result = disableCorsCredentialsForPlugin(
        "es.upv.paella.hlsLiveVideoFormat",
        result,
      );
    }

    return result;
  };

  const getVideoIdFromPath = () => {
    let path = window.location.pathname;
    if (path.endsWith("/")) {
      path = path.slice(0, -1);
    }
    path = path.split("/");
    const videoIdFromPath = path[path.length - 1];
    return videoIdFromPath;
  };

  useEffect(() => {
    const loadPaella = async () => {
      console.debug("Initializing Paella Player plugins context...");
      const initParams = {
        configResourcesUrl: "/paella-config/",
        configUrl: "/paella-config/config.json",
        loadConfig: customLoadConfig,
        getManifestUrl: getManifestUrlFunction, // get the video manifest url
        getVideoId: getVideoIdFromPath,
        customPluginContext: [
          getCernCustomPluginsContext(),
          getBasicPluginContext(),
          getSlidePluginContext(),
        ],
      };
      console.log("Initializing Paella Player plugins context... OK");
      paella.current = new Paella("player-container", initParams);
      console.log("Initializing Paella Player... OK");

      try {
        await paella.current.loadManifest();
        console.log("Loading video manifest... OK");
        await utils.loadStyle("/paella-config/style.css");
        console.debug("Loading styles from Paella Core API... OK");
      } catch (error) {
        console.error(error);
      }
    };
    if (!paella.current) {
      loadPaella();
    }
  }, []);

  if (!paella && !paella.current) {
    return <div>Loading player...</div>;
  }

  return (
    <div
      id="player-container"
      className="player-container"
      style={{
        minHeight: "100vh",
      }}
    />
  );
}

export default Player;
